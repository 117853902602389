// socket setup
import socketIOClient from "socket.io-client";
const  socket_endpoint  = "https://live.realtimeaudio.net:3000";
// const  socket_endpoint  = "http://localhost:8881";

let socket;

export const cloneSocket = () => {
    if (!socket) {
        socket = socketIOClient(socket_endpoint);
    }
    return socket;
}

export const socketEmit = (event, message) => {
    cloneSocket();
    socket.emit(event, message);
}

export const socketOn = (event, message) => {
    cloneSocket();
    socket.on(event, message);
}