var translation = {
    defaultLang: "en",
    lang: "en",
    localePath: "locale",
    init: function (lang) {
        this.lang = lang;
    },
    readFile: function () {
        let data = {}
        try {
            data = require("./" + this.localePath + "/" + this.lang + ".json")
        } catch (e) {
            data = require("./" + this.localePath + "/" + this.defaultLang + ".json")
        }
        return data;
    },
    t: function (input) {
        let localFile = this.readFile()
        if (localFile[input] != undefined) return localFile[input];
        return input;
    }
}
module.exports = translation;
